<script setup>
import { ref } from 'vue';
import { NAVBAR_VALUE, ABOUT } from '@/constants/navbar';
import ArrowBold from './svg/ArrowBold.vue';
import Arrow from './svg/Arrow';
import { vScrollTo } from '@/directives/scrollTo';

const isOpenPopper = ref(false);
const itemsList = ref([]);

const openLinksList = (index) => {
  if (!itemsList.value.includes(index)) {
    itemsList.value.push(index);
  } else {
    itemsList.value = itemsList.value.filter((n) => n !== index);
  }
};
</script>

<template>
  <nav class="tablet_navbar">
    <router-link class="tablet_navbar__img_container" to="/">
      <img
        src="@/assets/svg/favicon.svg"
        alt="favicon"
        @click="isOpenPopper = false"
      />
    </router-link>
    <div class="tablet_navbar__burger">
      <img
        v-if="!isOpenPopper"
        src="@/assets/svg/burger.svg"
        alt="burger"
        @click="isOpenPopper = true"
      />
      <img
        v-else
        src="@/assets/svg/close.svg"
        alt="close"
        @click="isOpenPopper = false"
      />
      <div v-if="isOpenPopper" class="tablet_navbar__burger-list">
        <div>
          <div v-for="(tab, index) in NAVBAR_VALUE" :key="index">
            <div class="tab" @click="openLinksList(index)">
              {{ tab.name }}
              <ArrowBold
                :direction="!itemsList.includes(index) ? 'right' : 'down'"
              />
            </div>
            <div :class="{ 'tab-hidden': !itemsList.includes(index) }">
              <router-link
                v-for="value in tab.links"
                :key="value.name"
                :to="value.link"
                style="cursor: pointer"
                @click="isOpenPopper = false"
              >
                <p
                  class="tablet_navbar__buttons-button_link"
                  v-text="value.name"
                />
              </router-link>
            </div>
          </div>
          <div @click="isOpenPopper = false">
            <router-link class="tab" to="/use-cases"> Portfolio </router-link>
          </div>
          <div>
            <div class="tab" @click="openLinksList('about')">
              {{ ABOUT.name }}
              <ArrowBold
                :direction="!itemsList.includes('about') ? 'right' : 'down'"
              />
            </div>
            <div :class="{ 'tab-hidden': !itemsList.includes('about') }">
              <router-link
                v-for="value in ABOUT.links"
                :key="value.name"
                :to="value.link"
                style="cursor: pointer"
                @click="isOpenPopper = false"
              >
                <p
                  class="tablet_navbar__buttons-button_link"
                  v-text="value.name"
                />
              </router-link>
            </div>
          </div>
          <div
            v-scroll-to="'contacts'"
            class="tablet_navbar__buttons-contact_btn name"
            @click="isOpenPopper = false"
          >
            Contacts
          </div>
        </div>
        <a
          href="mailto:info@lightvalley.ae"
          class="tablet_navbar__burger-list-lets_talk"
        >
          Let’s Talk
          <Arrow class="ml-20" />
        </a>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
.tablet_navbar {
  position: fixed;
  z-index: 10;
  top: 0;
  display: none;
  width: 100%;
  height: 9.6rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--color-gray-light);
  background-color: var(--color-white);

  &__burger {
    margin-right: 5.1rem;

    &-list {
      position: fixed;
      z-index: 9999;
      top: 9.6rem;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 3rem 0 4.8rem;
      background-color: var(--color-white);
      overflow-y: scroll;
      overscroll-behavior: contain;
      transition: all 0.3s var(--easing);

      .tab {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding: 1.6rem 2.4rem;
        font-size: 2.8rem;
        line-height: 4rem;
        cursor: pointer;

        &-hidden {
          display: none;
          transition: all 0.3s var(--easing);
        }

        &-show {
          display: block;
        }
      }

      &-lets_talk {
        min-height: 6.4rem;
        padding: 0 4.8rem;
        align-items: center;
        display: flex;
        border: 0;
        margin: 0 2.4rem;
        font-size: 1.8rem;
        line-height: 2.4rem;
        justify-content: center;
        background: linear-gradient(
          95.87deg,
          #7476ed -31.2%,
          #6e93e3 20.75%,
          #5fd3cb 111.29%
        );
        color: var(--color-white);
        cursor: pointer;
        img {
          padding-left: 2rem;
        }
      }
    }
  }

  &__img_container {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 4.5rem;
    border-right: 1px solid var(--color-gray-light);
  }

  &__buttons {
    height: 100%;

    &-button_link {
      padding: 1.6rem 3.2rem;
      color: var(--color-black);
      font-size: 1.7rem;
      line-height: 2.4rem;
    }

    &-contact_btn {
      padding: 1.6rem 2.4rem;
      border: 0;
      background: none;
      cursor: pointer;
      font-size: 2.8rem;
      line-height: 4rem;
    }
  }

  @media (max-width: $tablet-large-width) {
    display: flex;
  }

  @media (max-width: $mobile-width-middle) {
    height: 6.4rem;
    .tablet_navbar__burger-list {
      top: 6.4rem;
    }
    &__img_container {
      padding: 0 2.4rem;
    }
  }
}
</style>
